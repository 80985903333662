<template>
  <v-toolbar class="mt-4" elevation="0" style="background: none;">
    <v-toolbar-title class="font-weight-bold">{{ this.toolbarName }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn v-if="!openDetails && (authoritySlug === 'super-admin' || permissions)"
      @click="newData" color="primary" dark>
      + Adicionar
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: "Toolbar",
  props: {
    toolbarName: {
      default: ""
    },
    openDetails: {
      default: false
    },
    authoritySlug: {
      default: 'super-admin'
    },
    permissions: {
      default: false
    }
  },
  methods: {
    newData() {
      this.$parent.create();
    }
  }
}
</script>

<style>
.v-toolbar__content{
  padding: 0 !important;
}
</style>
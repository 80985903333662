<template>
  <v-col
      :cols="column"
      :md="responsive.md"
  >
    <p>
      <span><b>{{ name }}</b></span> <br />
      <span class="font-weight-light">
        <the-mask v-if="sensitive"
                  :key="componentKey"
                  :mask="getMask"
                  :value="item"
                  :masked="true"
                  style="width: 8rem;" />
        <span v-else>{{ item }}</span>
      </span>
      <v-btn
          v-if="sensitive"
          icon
          @click="toggleHidden"
      >
        <v-icon>{{ hidden ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
      </v-btn>
    </p>
  </v-col>
</template>

<script>
import {TheMask} from 'vue-the-mask'

export default {
  name: "DetailsItem",
  components: {TheMask},
  props: {
    column: {
      type: String,
      default: "4"
    },
    item: {
      required: true,
    },
    name: {
      required: true,
      type: String,
    },
    mask: {
      type: String,
      default: ''
    },
    sensitive: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: Object,
      default:()=> ({
        md:"0"
      })
    }
  },
  data() {
    return {
      hidden: false,
      componentKey: 0,
    }
  },
  computed: {
    getMask: function() {
      switch(this.mask) {
        case 'cpf':
          if(this.hidden) return ['###.xxx.xxx-xx'];
          return ['###.###.###-##'];

        case 'zip':
          return ['##.###-###'];
      }
      return '';
    }
  },
  mounted() {
    if(this.sensitive) this.hidden = true;
  },
  methods: {
    toggleHidden() {
      this.hidden = !this.hidden;
      this.componentKey += 1;
    }
  }
}
</script>

<style scoped>

</style>
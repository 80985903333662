import Vue from "vue";

const eventBus = new Vue({
  methods: {
    showPanelIndexes(show) {
      this.$emit("showPanelIndexes", show);
    }
  },
});

export default eventBus;

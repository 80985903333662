import CultivarBrands from "./cultivar-brands.service";

class CultivarBrand {
  constructor(payload = {}) {
    Object.assign(this, {
      ...CultivarBrand.build(payload),
    });
  }

  static build({
    id = "",
    name = "",
    is_active = "",
    created_at = "",
    updated_at = "",
  }) {
    return {
      id,
      name,
      is_active,
      created_at,
      updated_at,
    };
  }

  static newData(payload) {
    return new CultivarBrand(payload);
  }

  static list(params) {
    return new Promise((resolve, reject) => {
      CultivarBrands.list(params).then(
        (success) => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          list.data = data.data.map((item) => this.newData(item));
          resolve(list);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static all(culture_id) {
    return new Promise((resolve, reject) => {
      CultivarBrands.all(culture_id).then(
        (success) => {
          const {data} = success;
          const list = Object.assign({}, {...data});
          list.data = data.data.map(item => this.newData(item));
          resolve(list);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static create(payload) {
    return new Promise((resolve, reject) => {
      CultivarBrands.create(payload).then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static edit(payload = {}) {
    return new Promise((resolve, reject) => {
      CultivarBrands.edit(payload).then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static delete(payload) {
    return new Promise((resolve, reject) => {
      CultivarBrands
      .delete(payload)
      .then(success => {
        resolve(success);
      }, error => {
        reject(error);
      });
    });
  }
}

export default CultivarBrand;

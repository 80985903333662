<template>
  <v-card class="mb-12 rounded-xl">
    <v-card-title class="yellow-border">
      <v-container class="custom-container"
        v-if="authoritySlug === 'super-admin' || permissions">
        <slot></slot>
      </v-container>

      <slot name="other-title-details"></slot>

      <v-container class="custom-container mt-4" v-if="show">
        <slot name="other-row-details"></slot>
      </v-container>

      <v-container class="custom-container mt-4" v-if="showThirdRowDetails">
        <slot name="third-row-details"></slot>
      </v-container>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: "Details",

  props: {
    show: {
      type: Boolean,
      default: false
    },
    showThirdRowDetails: {
      type: Boolean,
      default: false
    },
    authoritySlug: {
      default: 'super-admin'
    },
    permissions: {
      default: false
    }
  }
}
</script>

<style scoped>
.custom-container {
  border: 1px solid #E3E3E3;
  border-radius:5px;
  padding: 1rem 2rem 0.75rem 2rem;
}
</style>
<template> 
  <div>
    <div v-if="openDetails" class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <toolbar :toolbarName="dynamicToolbarName" :openDetails="openDetails"/>

    <form-dialog :dialog="formDialog" :formName="dynamicFormName">
      <v-form ref="formCultivarBrand">
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Nome da Marca"/>
            <simple-text-field v-model="payload.name"
                               required
                               placeholder="Nome"/>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <custom-label label="Status"/>
            <simple-select
              v-model="payload.is_active"
              :items="statusType"
              itemText="text"
              itemValue="value"
              class="mr-2"
              placeholder="Status"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
            />
          </v-col>
        </v-row>
      </v-form>
    </form-dialog>

    <search-bar v-if="!openDetails" v-model="search" @externalFiltersClear="clearFilters" :noSecondaryInput="false">
      <simple-select
          v-model="isActiveSearch"
          :items="statusType"
          @input="filterByStatus()"
          itemText="text"
          itemValue="value"
          class="mr-2"
          placeholder="Status"
          height="0"
          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
      />
    </search-bar>

    <row-details v-if="openDetails">
      <v-row align="center">
        <v-col class="d-flex justify-start">
          <span class="font-weight-bold">Detalhes</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end">
          <v-icon @click="edit()"> mdi-pencil</v-icon>
        </v-col>
      </v-row>

      <v-row>
        <col-details column="4" name="Nome" :item="payload.name"/>
        <col-details column="4" name="Status" :item="getStatus(payload.is_active)"/>
      </v-row>
    </row-details>

    <data-table :headers="headers"
                :fetching="fetching"
                :data="data"
                :search="search.text"
                :objectName="objectName"
                @loadAgain="loadAgain"
                v-else
    >
      <template v-slot:[`item.is_active`]="{ item }">
        {{ getStatus(item.is_active) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon style="color:#707070;" class="ml-4" small @click="view(item)">
          mdi-information-outline
        </v-icon>

        <v-icon class="ml-4" small @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <confirm-destroy-dialog
      :title="payload.name"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
  import Toolbar from "@/components/crud/Toolbar";
  import FormDialog from "@/components/crud/FormDialog";
  import SearchBar from "@/components/layouts/SearchBar.vue";
  import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
  import DataTable from "@/components/data-table/DataTable";
  import SimpleTextField from "@/components/inputs/SimpleTextField.vue";
  import CustomLabel from "@/components/texts/CustomLabel";
  import RowDetails from "@/components/crud/Details";
  import ColDetails from "@/components/crud/DetailsItem";
  import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";

  import CultivarBrands from "@/domain/cultivar-brands/cultivar-brands";

  import eventBus from "@/event-bus.js";

  export default {
    name: 'CultivarBrands',

    components: {
      Toolbar,
      FormDialog,
      SearchBar,
      SimpleSelect,
      DataTable,
      SimpleTextField,
      CustomLabel,
      RowDetails,
      ColDetails,
      ConfirmDestroyDialog
    },

    data() {
      return {
        toolbarName: 'Cadastro de Marcas',
        openDetails: false,
        formDialog: false,
        objectName: 'marcas dos cultivares',
        formName: 'Adicionar Nova Marca',

        statusType: [
          {text: "Ativo", value: true},
          {text: "Inativo", value: false}
        ],

        search: {},
        isActiveSearch: "",
        fetching: true,

        headers: [
          {text: 'ID', align: 'start', value: 'id'},
          {text: 'Marca', value: 'name'},
          {text: 'Status', value: 'is_active',
            filter: value => {
              if (this.isActiveSearch === "") return true;
              return value === this.search.is_active;
            }
          },
          {text: 'Detalhes', value: 'actions', sortable: false},
        ],

        cultivarBrandService: null,
        showConfirmDestroyDialog: false,
        payload: {},
        data: []
      };
    },

    methods: {
      getCultivarBrands() {
        this.fetching = true;
        this.data     = [];

        this.cultivarBrandService.list().then(result => {
          result.data.map(item => {
            this.data.push(item);
          });
        })
        .catch(({response}) => {
          const {error} = response.data;
          this.$toasted.show(error, {
            type: 'error'
          });
        });

        this.fetching = false;
      },

      getStatus(status) {
        if (status === true) return "Ativo";
        return "Inativo";
      },

      initialize() {
        this.cultivarBrandService = CultivarBrands;
        this.payload = this.cultivarBrandService.newData();

        this.getCultivarBrands();
      },

      view(item) {
        this.payload = Object.assign({}, item);
        this.openDetails = true;
      },

      edit() {
        this.formDialog = true;
      },

      deleteItem(item) {
        this.payload = Object.assign({}, item);
        this.showConfirmDestroyDialog = true;
      },

      closeConfirmDestroyDialog() {
        this.showConfirmDestroyDialog = false;
      },

      create() {
        this.payload = {};
        this.payload.is_active = true;
        this.formDialog = true;
      },

      closeFormDialog() {      
        this.formDialog = false;
      },

      closeView() {
        this.openDetails = false;
        this.initialize();
      },

      save() {
        if (!this.$refs.formCultivarBrand.validate()) {
          return;
        }

        let loader = this.$loading.show();

        if (!this.openDetails) {
          this.cultivarBrandService.create(this.payload)
            .then(() => {
              loader.hide();
              this.closeFormDialog();
              this.initialize();
              this.$toasted.show("Marca cadastrada com sucesso.", {
                type: 'success'
              });
            })
            .catch(({response}) => {
              loader.hide();
              const {error} = response.data;
              this.$toasted.show(error, {
                type: 'error'
              });
            });
        } else {
          this.cultivarBrandService.edit(this.payload)
            .then(() => {
              loader.hide();
              this.closeFormDialog();
              this.$toasted.show("Marca atualizada com sucesso.", {
                type: "success",
              });
            })
            .catch(({response}) => {
              loader.hide();
              const {error} = response.data;
              this.$toasted.show(error, {
                type: "error",
              });
            });
        }
      },

      async destroy() {
        const loader = this.$loading.show();

        await this.cultivarBrandService.delete(this.payload).then(() => {
          loader.hide();
          this.getCultivarBrands();
          this.showConfirmDestroyDialog = false;
          this.$toasted.show("Marca removida com sucesso.", {
            type: "success",
          });
        })
        .catch(() => {
          loader.hide();
          this.showConfirmDestroyDialog = false;
          this.$toasted.show("Marca não foi removida.", {
            type: 'error'
          });
        });
      },

      filterByStatus() {
        this.search.is_active = this.isActiveSearch;
      },

      clearFilters() {
        this.search = {};
        this.isActiveSearch = "";
      },

      loadAgain() {
        this.initialize();
      }
    },

    computed: {
      dynamicToolbarName() {
        if (this.openDetails) {
          return this.toolbarName + ' / Detalhes';
        }

        return this.toolbarName;
      },

      dynamicFormName() {
        if (this.openDetails) {
          return "Editar Marcas";
        }

        return this.formName;
      }
    },

    beforeMount() {
      this.initialize();
    },

    mounted() {
      eventBus.showPanelIndexes(false);
    }
  }
</script>
import http from '@/services/http.service';

const apiUrl = '/cultivar-brands';

class CultivarBrandsService {
    static list(params) {
        return http.get(`${apiUrl}`, { params });
    }
    static all(culture_id) {
        return http.get(`${apiUrl}-all${culture_id ? '/'+ culture_id : ''}`);
    }
    static create(payload) {
        return http.post(`${apiUrl}`, payload);
    }
    static edit(payload) {
        return http.put(`${apiUrl}/${payload.id}`, payload);
    }
    static get(id) {
        return http.get(`${apiUrl}/${id}`);
    }
    static delete(payload) {
        return http.delete(`${apiUrl}/${payload.id}`);
    }
}

export default CultivarBrandsService;
